<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.title"
                placeholder="标题"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                placeholder="类型"
                style="width: 150px"
                v-model="form.type"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                placeholder="状态"
                style="width: 150px"
                v-model="form.status"
              >
                <a-select-option
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="标题" data-index="title"> </a-table-column>

        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="informType" :dictValue="text.type" />
          </template>
        </a-table-column>
        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="informStatus"
              :dictValue="String(text.status)"
              iconType="badge"
            />
          </template>
        </a-table-column>
        <a-table-column title="是否公开" align="center">
          <template slot-scope="text">
            <a-tag color="green" v-if="text.isPublic"> 公开 </a-tag>
            <a-tag color="red" v-else> 不公开 </a-tag>
          </template>
        </a-table-column>
        <a-table-column title="发布人" data-index="informerName" align="center">
        </a-table-column>
        <a-table-column title="发布时间" data-index="createAt" align="center">
        </a-table-column>
        <a-table-column title="修改人" data-index="modifierName" align="center">
        </a-table-column>
        <a-table-column title="修改时间" data-index="updateAt" align="center">
        </a-table-column>

        <a-table-column title="操作" align="right">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission(topPath)"
                @click.prevent="top(text)"
              >
                {{ text.sequence ? "取消置顶" : "置顶" }}
              </a>

              <a
                href="#"
                v-if="$getPermission(editPath)"
                @click.prevent="editText(text)"
                >修改</a
              >

              <a
                href="#"
                v-if="$getPermission(detailPath)"
                @click.prevent="goDetail(text)"
                >处理</a
              >

              <template
                v-if="$getPermission(closePath) && String(text.status) !== '3'"
              >
                <a href="#" @click.prevent="closeText(text)">关闭</a>
              </template>

              <template v-if="$getPermission(deletePath)">
                <a
                  href="#"
                  class="danger"
                  @click.prevent="deleteText(text)"
                  v-if="String(text.status) !== '-1'"
                  >删除</a
                >
                <a href="#" v-else @click.prevent="cancel(text)">恢复</a>
              </template>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>
  
  
<script>
import watermark from "@/mixins/watermark";
import { fetchTable, setTop, close, remove, recover } from "./api";
import { mapGetters } from "vuex";

export default {
  mixins: [watermark],

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    editPath() {
      const path = this.$route.path;
      return path.replace("/table", "/edit");
    },
    detailPath() {
      const path = this.$route.path;
      return path.replace("/table", "/detail");
    },

    topPath() {
      const path = this.$route.path;
      return path.replace("/table", "/top");
    },
    closePath() {
      const path = this.$route.path;
      return path.replace("/table", "/close");
    },
    deletePath() {
      const path = this.$route.path;
      return path.replace("/table", "/delete");
    },

    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("informType");
    },
    statusList() {
      return this.findDataDict("informStatus");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;

      fetchTable({
        pageNum: current,
        pageSize: pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list) && typeof res.totalSize === "number") {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    top(text) {
      const hide = this.$message.loading("操作中...");
      setTop({
        id: text.id,
        isTop: text.sequence ? 0 : 1,
      })
        .then(() => {
          this.reset();
        })
        .finally(() => {
          hide();
        });
    },

    editText(text) {
      this.$router.push(this.editPath + "?id=" + text.id);
    },

    goDetail(text) {
      this.$router.push(this.detailPath + "?id=" + text.id);
    },

    closeText(text) {
      const that = this;
      this.$confirm({
        title: "关闭后无法恢复，是否继续？",
        onOk() {
          close({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    cancel(text) {
      recover({
        id: text.id,
      }).then(() => {
        this.getList();
      });
    },
    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>